import Sharer from './sharer';
import ShareButton from './ShareButton';

let sharer: Sharer | null = null;

document.querySelectorAll('[udesly-web-share]').forEach(el => {
    let mode = el.getAttribute('udesly-web-share') || 'auto';
    // @ts-ignore
    if (mode !== 'auto' && mode !== 'dark' && mode !== 'light') {
        mode = 'auto';
    }
    if (!sharer) {
        sharer = new Sharer(mode as 'auto' | 'dark' | 'light');
    }

    new ShareButton(el as HTMLElement, sharer);

});
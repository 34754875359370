import Sharer, { ShareIntent } from './sharer';

export default class ShareButton {
	private title?: string;
	private text?: string;
	private url?: string;
	private sharer: any;

	constructor(el: HTMLElement, sharer: Sharer) {
		this.sharer = sharer;

		if (el.closest('.w-dyn-item')) {
			// It's a collection list item
			const item = el.closest('.w-dyn-item');
			if ((item as HTMLElement).querySelector('[web-share="title"]')) {
				this.title =
					((item as HTMLElement).querySelector('[web-share="title"]') as HTMLElement).textContent || '';
			} else {
				if ((item as HTMLElement).querySelector('h1, h2, h3, h4, h5, h6') as HTMLElement) {
					this.title =
						((item as HTMLElement).querySelector('h1, h2, h3, h4, h5, h6') as HTMLElement).textContent ||
						'';
				}
			}

			if ((item as HTMLElement).querySelector('[web-share="description"]')) {
				this.text =
					((item as HTMLElement).querySelector('[web-share="description"]') as HTMLElement).textContent || '';
			} else {
				if ((item as HTMLElement).querySelector('p') as HTMLElement) {
					this.text = ((item as HTMLElement).querySelector('p') as HTMLElement).textContent || '';
                }
			}
			
			this.url = el.getAttribute('href') == '#'? document.location.href : el.getAttribute('href') ||''

		} else {
			this.title = document.title;
			const meta = document.querySelector('meta[name="description"]');
			this.text = meta ? meta.getAttribute('content') || '' : '';
			this.url = el.getAttribute('href') == '#'? document.location.href : el.getAttribute('href') ||''
		}

		if (!this.url) {
			el.remove();
			return;
		}

		el.addEventListener('click', (e) => {
			e.preventDefault();
			const toShare: ShareIntent = { url: this.url || '' };
			if (this.title != '') {
				toShare.title = this.title;
			}
			if (this.text != '') {
				toShare.text = this.text;
			}
			this.sharer.share(toShare);
		});
	}
}

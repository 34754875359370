// @ts-ignore
import fakeSharer from './polyfill';

export type ShareIntent = {
	title?: string;
	text?: string;
	url: string;
};

export type polyfillMode = 'auto' | 'dark' | 'light';

export type Theme = 'light' | 'dark';

export default class Sharer {
	private nativeSupport: boolean;
	private polyfilledSharer: any;
	private mode: polyfillMode;

	constructor(mode: polyfillMode = 'auto') {
		// @ts-ignore
		this.nativeSupport = navigator.share;
		this.mode = mode;

		if (!this.nativeSupport) {
			this.polyfill();
		}
		switch (this.mode) {
			case 'auto':
                setColorScheme((theme: Theme) => this.enableTheme(theme));
                break;
			case 'dark':
                this.enableTheme('dark');
                break;
			case 'light':
                this.enableTheme('light');
                break;
		}
	}

	private enableTheme(theme: Theme) {
		// Enables dark or light mode
		document.body.setAttribute('web-sharer-theme', theme);
	}

	share(toShare: ShareIntent) {
		
		if (this.nativeSupport) {
			// @ts-ignore
			navigator.share(toShare);
		} else {
            
			this.polyfilledSharer(toShare);
		}
	}

	private polyfill() {
        // create fake sharer
        this.polyfilledSharer = new fakeSharer();
	}
}

function setColorScheme(onChange: Function) {
	const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
	const isLightMode = window.matchMedia('(prefers-color-scheme: light)').matches;
	const isNotSpecified = window.matchMedia('(prefers-color-scheme: no-preference)').matches;
	const hasNoSupport = !isDarkMode && !isLightMode && !isNotSpecified;
	window.matchMedia('(prefers-color-scheme: dark)').addListener((e) => e.matches && onChange('dark'));
	window.matchMedia('(prefers-color-scheme: light)').addListener((e) => e.matches && onChange('light'));
	if (isDarkMode) onChange('dark');
	if (isLightMode) onChange('light');
	if (isNotSpecified || hasNoSupport) {
		const now = new Date();
		const hour = now.getHours();
		if (hour < 4 || hour >= 16) {
			onChange('dark');
		} else {
			onChange('light');
		}
	}
}
